export const floorAreaVerticalOpeningVisuals = [
  {
    name: "Demo",
    markdown: `![Create vertical opening in a floor area GIF](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
    `,
  },
  {
    name: "Before & After",
    markdown: `{% beforeAfter beforeImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelBefore.png" afterImageSource="https://assets.towerhunt.com/site/documentation/addFeeParcelAfter.png" /%}
    `,
  },
];

export const floorAreaVerticalOpening = {
  order: 37,
  name: "Create a vertical opening in a floor area",
  keyword: "atrium lobby double-height mezzanine ceiling",
  subcategory: "Properties",
  markdown: `# Create a vertical opening in a floor area

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}**Reputable users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Sometimes {% inlineRouterLink articleId="add-a-land-covering" %}buildings{% /inlineRouterLink %} include physical features like atriums, double-height floors, and mezzanine space. Conceptually, these can be thought of as {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %} where the ceiling of one or more {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %} has been removed to create a vertical opening. In many cases, these features have a material impact on the value of the assocated floor area. In Tower Hunt, you can represent vertical openings in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} to see where they are and tie that context to data about property value.

  {% callout type="tip" %}
  **Tip:** To use this feature a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} must span multiple {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %}. You can {% inlineRouterLink articleId="relocate-floor-areas" %}relocate a floor area{% /inlineRouterLink %} to change which levels it covers.
  {% /callout %}

  ## Steps

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" %}**Select**{% /inlineRouterLink %} a {% inlineRouterLink articleId="add-a-floor-area" %}floor area{% /inlineRouterLink %} that spans multiple {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %}.
  3. In the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}, click {% inlinePropertyDiagramIcon name="addVerticalOpenings" /%}.
  4. {% inlineAppIcon iconName="cursor" /%}{% inlineRouterLink articleId="select-a-property-diagram-part" sectionName="Placing new datapoints on the diagram" %}**Select**{% /inlineRouterLink %} one or more {% inlineRouterLink articleId="add-building-levels" %}building levels{% /inlineRouterLink %} that are open to the floor below.
  5. Click {% inlinePropertyDiagramIcon name="saveEditAction" /%} to save. A {% inlineAppIcon iconName="verticalOpening" /%}**vertical opening** indicator appears in the lower-right of the floor areas that are vertically open and their borders disappear to illustrate that the ceilings have been removed.
  {% /tablessContainer %}

  ## Visuals

  {% tabbedContainer tabs=$floorAreaVerticalOpeningVisuals /%}
  `,
};
